import React from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import img from '../images//minhaj.webp'
import Hero from '../components/Hero';

const Section = styled(Box)`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  @media (max-width: 600px) {
    padding: 60px 0;
  }
`;

const Column = styled(Grid)`
  align-items: center;
  text-align: center;
  padding: 60px 160px 0;
  @media (max-width: 900px) {
    padding: 0 60px 0;
  }
`;

const Img = styled(Grid)`
  background-image: url(${img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const LandingPage = () => {
  return (
    <>
      <Hero width={"75%"}>
        <Typography sx={{ typography: { md: 'h5', xs: 'subtitle1' } }}>Establishing the Divine Truth revealed by Almighty Allah</Typography>
        <Typography sx={{ typography: { md: 'body1', xs: 'body2' } }}>Turn your Face Single-mindedly to the True Faith and Adhere to the true Nature on which Allah has created Human Beings (Fitrah).
          (Instinct - Mould Fashioned) Created by Allah can never be Changed. That is the True, Straight Faith.although most people do not Know. (Rum-30)</Typography>
        <Typography sx={{ typography: { md: 'body1', xs: 'body2' } }}>
          1پس (اے نبیؐ اور نبیؐ کے پیروو) یک سُو ہو کر اپنا رخ اس دین2 کی سمت میں جمادو،3 قائم ہو جاؤ اس فطرت پر جس پر اللہ تعالیٰ نےانسانوں کو پیدا کیا ہے،4 اللہ کی بنائی ہوئی ساخت بدلی نہیں جا سکتی،5 یہی بالکل راست اور درست دین ہے،6 مگر اکثر لوگ جانتے نہیں ہیں
        </Typography>
        <Typography variant='overline'>
          اللہ ہمارا حامی و ناصر ہو۔
        </Typography>
      </Hero>
      <Section>
        <Grid container>
          <Column item xs={12} md={6}>
            <Typography sx={{ typography: { md: 'h4', xs: 'subtitle1' } }}>Reforms on Muslim Identity Crisis</Typography>
            <Typography sx={{ typography: { md: 'subtitle1', xs: 'body2' }, textAlign: "justify" }}>Unifying Muslims accross the border on a single objective. Painting people in colour of Allah and colour of touheed. Calling on Muslim Armies to march towards Masjid Al Aqsa united under one Flag i.e "La ilaha illAllah Muhammadur rasulullah". No doubt one day Muslim Armies will march towards Masjid Al Aqsa as predicted by our beloved Prophet Muhammad PBUH.</Typography>
            <Button component={Link} variant="outlined" color="primary" to="/join">Join us</Button>
          </Column>
          <Img item xs={12} md={6} />
        </Grid>
      </Section>
    </>
  );
};

export default LandingPage;